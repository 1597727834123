import {useAuthStore} from "~/stores/auth";

export default defineNuxtRouteMiddleware(async () => {
  const auth = useAuthStore()

  if (! auth.user) {
    // TODO: store page to redirect to after login (redirect to) (get path from router.. or params into this)

    return navigateTo("/login", { replace: true })
  }
})
